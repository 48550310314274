import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
    Checkbox,
    Grid, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem
} from '@material-ui/core';
import {
    createMaster,
    navLinks,
    getEmployeePosts,
    getBusinessUnits,
    getEmployeeStatuses,
    getJuridicalPersons,
    getDepartments,
    getEmployee,
    getEmployeeSpecializations,
    employeePostMaster,
    employeePostAdministrator,
    getHumanEmployees
} from "../../services/organization";
import LeftBar from "../../components/left-bar/LeftBar";
import NavigationLinks from "../../components/navigation-links/NavigationLinks";
import Content from "../../components/content/Content";
import {connect} from "react-redux";
import messageDialogActions from "../../components/dialogs/messageDialog-acions";
import ActionButton from "../../components/button/ActionButton";
import AnyField from "../../components/fields/AnyField";
import PhoneField from "../../components/fields/PhoneField";
import DateField from "../../components/fields/DateField";
import UploadFilesField from "../../components/fields/UploadFilesField";
import {withRouter} from "react-router";
import CheckboxField from "../../components/fields/CheckboxField";
import MultiSelectFromItemsField from "../../components/fields/MultiSelectFromItemsField";
import {getCurrencies, getGratuityQRImage, gratuityQRTypes} from "../../services/common";
import {getGenders} from "../../services/guests";
import SelectFromItemsField from "../../components/fields/SelectFromItemsField";
import confirmDialogActions from "../../components/dialogs/confirmDialog-acions";
import ColorField from "../../components/fields/ColorField";
import FloatField from "../../components/fields/FloatField";
import {isValidRequiredL18nString} from "../../utils/validators";
import AutocompleteSelectField from "../../components/fields/AutocompleteSelectField";

export default @withRouter
@connect(null, {
    showMessage: messageDialogActions.show,
    showConfirm: confirmDialogActions.show,
})
@withStyles(theme => ({
    container: {
        width: 900,
        marginBottom: 10,
    },
    changeParlour: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        minHeight: 400,
    },
}))
class MasterViewPage extends React.Component {

    state = {
        oldMaster: {
            employeeStatus: 'working',
        },
        master: {
            id: null,
            name: null,
            nameAll: {},
            businessUnitsId: [],
            employeeStatus: 'working',
            employeePost: 'master',
            departmentId: null,
            supervisorId: null,
            photo: null,
            certificate: null,
            address: null,
            birthDate: null,
            birthPlace: null,
            employmentStart: new Date(),
            inn: null,
            comment: null,
            description: null,
            juridicalPersonId: null,
            mainBusinessUnitId: null,
            canHaveMoney: false,
            firstName: null,
            lastName: null,
            thirdName: null,
            specializations: [],
            gender: null,
            currenciesInBillReport: [],
            hideInCalendar: false,
            showOnSite: false,
            notPerson: false,
            lineColor: null,
            exportAdminsAndMastersDashboard: false,
            mastersMaxWorkHours: null
        },
        types: [],
        businessUnits: [],
        employeePosts: [],
        employeeStatuses: [],
        juridicalPersons: [],
        departments: [],
        filterParlour: null,
        specializations: [],
        genders: [],
        currencies: [],
        employees: [],
    };

    componentDidMount() {
        const path = this.props.location.pathname;
        const reId = new RegExp('[^/]+$');
        const resultId = reId.exec(path);
        if (resultId.length && resultId[0] !== '0') {
            console.log(resultId[0]);
            getEmployee(resultId[0])
                .then(response => {
                    if (response.success) {
                        this.setState({
                            master: response.data,
                            oldMaster: response.data,
                        })
                    }
                });
        }

        getEmployeeStatuses()
            .then(response => {
                if (response.success) {
                    this.setState({
                        employeeStatuses: response.data,
                    })
                }
            });

        getEmployeePosts()
            .then(response => {
                if (response.success) {
                    response.data.sort((a, b) => {
                        if (a.text > b.text) {
                            return 1;
                        }
                        if (a.text < b.text) {
                            return -1;
                        }

                        return 0;
                    });
                    this.setState({
                        employeePosts: response.data,
                    })
                }
            });

        getHumanEmployees()
            .then(response => {
                if (response.success) {
                    this.setState({
                        employees: [
                            { id: '0', name: '---Нет---'},
                            ...response.data
                        ],
                    });
                }
            });

        getJuridicalPersons({companyDepartment: true})
            .then(response => {
                if (response.success) {
                    this.setState({
                        juridicalPersons: response.data,
                    })
                }
            });

        getBusinessUnits()
            .then(response => {
                if (response.success) {
                    this.setState({
                        businessUnits: response.data,
                    })
                }
            });

        getDepartments()
            .then(response => {
                if (response.success) {
                    this.setState({
                        departments: response.data,
                    })
                }
            });

        getEmployeeSpecializations()
            .then(response => {
                if (response.success) {
                    this.setState({
                        specializations: response.data,
                    });
                }
            });

        getGenders()
            .then(response => {
                if (response.success) {
                    this.setState({
                        genders: response.data,
                    });
                }
            });

        getCurrencies()
            .then(response => {
                if (response.success) {
                    this.setState({
                        currencies: response.data
                    });
                }
            });
    }

    handleChange = event => {
        const prop = event.target.name;
        const {master} = this.state;
        this.setState({master: {...master, [prop]: event.target.value}});
    };

    handleChangeI18n = event => {
        const [prop, locale] = event.target.id.split('#');
        this.setState({
            master: {
                ...this.state.master,
                [prop]: {
                    ...this.state.master[prop],
                    [locale]: event.target.value,
                }
            }
        });
    };

    handleHistory = () => {
        this.props.history.push(`/organization/history-employee/${this.state.master.id}`);
    };

    handleChangeDate = prop => date => {
        const {master} = this.state;
        this.setState({
            master: {
                ...master,
                [prop]: date,
            }
        });

    };

    handleChangeFile = prop => event => {
        const {master} = this.state;
        this.setState({
            master: {
                ...master,
                [prop]: event.target.value,
            }
        });
    };

    doSave = clearActivities => {
        createMaster({
            ...this.state.master,
            photo: this.state.master.photo ? this.state.master.photo.id : null,
            certificate: this.state.master.certificate ? this.state.master.certificate.id : null,
            clearActivities,
        })
            .then(response => {
                if (response.success) {
                    this.props.history.push(`/organization/masters`);
                } else {
                    this.props.showMessage(response.error ? response.error.message : response.message);
                }
            });
    }

    handleSave = () => {
        const { master } = this.state;

        // Если установлен флажок "Показывать на сайте", проверяем наличие Фото и Сертификата
        if (master.showOnSite) {
            const photo = master.photo;
            const certificate = master.certificate;

            // Проверяем, загружены ли файлы Фото и Сертификат
            if (!photo || !certificate) {
                this.props.showMessage("Сохранение карточки без Фото и Сертификата невозможно. Загрузите их");
                return;
            }

            // Проверяем формат файлов
            const isJpg = (file) => file && file.name.toLowerCase().endsWith(".jpg");

            if (!isJpg(photo) || !isJpg(certificate)) {
                this.props.showMessage("К загрузке принимаются только файлы формата .jpg");
                return;
            }
        }

        // Сохраняем данные, если все проверки пройдены
        const { oldMaster } = this.state;
        if (oldMaster.employeeStatus !== master.employeeStatus && master.employeeStatus === "fired") {
            this.props.showConfirm({
                message: "Очистить график сотрудника, начиная с текущего момента?",
                title: "Увольнение сотрудника",
                onClose: this.doSave,
            });
        } else {
            this.doSave(false);
        }
    };

    handleChangeParlour = id => () => {
        const {master} = this.state;

        if (master.businessUnitsId.indexOf(id) < 0) {
            master.businessUnitsId.push(id);
        } else {
            master.businessUnitsId = master.businessUnitsId.filter(businessUnitId => businessUnitId !== id);
        }

        this.setState({
            master: master,
        })
    };

    handleFilterParlour = (event) => {
        this.setState({
            filterParlour: event.target.value && event.target.value !== '' ? event.target.value : null
        })
    };

    isSaveDisabled() {
        const { master } = this.state;

        return !isValidRequiredL18nString(master.nameAll)
            || !(master.businessUnitsId && master.businessUnitsId.length)
            || !master.juridicalPersonId
            || !master.departmentId
            || ((master.employeePost === employeePostMaster) && (!master.specializations || !master.specializations.length));
    };

    render() {
        const {classes} = this.props;
        const {
            master,
            employeePosts,
            employeeStatuses,
            juridicalPersons,
            departments,
            businessUnits,
            filterParlour,
            specializations,
            genders,
            currencies,
            employees,
        } = this.state;

        const regexFilterParlour = new RegExp(filterParlour, 'gi');

        let gratuityLinkQR = null;
        if (master.id) {
            gratuityLinkQR = getGratuityQRImage(master.id, gratuityQRTypes.employee);
        }

        return (
            <React.Fragment>
                <LeftBar navigationLinks={<NavigationLinks links={navLinks}/>}>
                    <br/>
                    <br/>
                    <ActionButton onClick={this.handleHistory}>История изменения</ActionButton>
                    <ActionButton onClick={this.handleSave} disabled={this.isSaveDisabled()}>Сохранить</ActionButton>
                </LeftBar>
                <Content title="Карточка сотрудника">
                    <Grid container spacing={2} className={classes.container}>
                        <Grid item xs={8}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <AnyField
                                        id="nameAll#en"
                                        label="Отображаемое имя, EN"
                                        value={master.nameAll.en}
                                        required
                                        fullWidth
                                        onChange={this.handleChangeI18n}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <AnyField
                                        id="nameAll#ru"
                                        label="Отображаемое имя, RU"
                                        value={master.nameAll.ru}
                                        required
                                        fullWidth
                                        onChange={this.handleChangeI18n}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <AnyField
                                        id="nameAll#cs"
                                        label="Отображаемое имя, CS"
                                        value={master.nameAll.cs}
                                        required
                                        fullWidth
                                        onChange={this.handleChangeI18n}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <AnyField
                                        name="employeeStatus"
                                        label="Статус"
                                        value={master.employeeStatus}
                                        onChange={this.handleChange}
                                        error={!master.employeeStatus}
                                        fullWidth
                                        required
                                        select
                                    >
                                        {employeeStatuses.map(item => <MenuItem key={item.id}
                                                                                value={item.id}>{item.text}</MenuItem>)}
                                    </AnyField>
                                </Grid>
                                <Grid item xs={4}>
                                    <AnyField
                                        name="employeePost"
                                        label="Должность"
                                        value={master.employeePost}
                                        onChange={this.handleChange}
                                        fullWidth
                                        error={!master.employeePost}
                                        required
                                        select
                                    >
                                        {employeePosts.map(item => <MenuItem key={item.id}
                                                                             value={item.id}>{item.text}</MenuItem>)}
                                    </AnyField>
                                </Grid>
                                <Grid item xs={4}>
                                    <AutocompleteSelectField
                                        name="supervisorId"
                                        label="Руководитель"
                                        value={master.supervisorId}
                                        onChange={this.handleChange}
                                        fullWidth
                                        options={employees.map((employee) => ({value: employee.id, label: employee.name}))}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DateField
                                        label="Начало работы"
                                        value={master.employmentStart}
                                        format="DD.MM.YYYY"
                                        onChange={this.handleChangeDate('employmentStart')}
                                        openTo={master.employmentStart ? 'date' : 'year'}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <MultiSelectFromItemsField
                                        name="specializations"
                                        items={specializations}
                                        emptySelectedLabel="Не требуется"
                                        value={master.specializations}
                                        onChange={this.handleChange}
                                        label="Специализации"
                                        fullWidth
                                        required={master.employeePost === employeePostMaster}
                                        error={(master.employeePost === employeePostMaster) && (!master.specializations || !master.specializations.length)}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <AnyField
                                        name="juridicalPersonId"
                                        label="Юридическое лицо"
                                        value={master.juridicalPersonId}
                                        onChange={this.handleChange}
                                        error={!master.juridicalPersonId}
                                        fullWidth
                                        select
                                    >
                                        <MenuItem value={null}>Не выбрано</MenuItem>
                                        {juridicalPersons.map(item => <MenuItem key={item.id}
                                                                                value={item.id}>{item.shortName}</MenuItem>)}
                                    </AnyField>
                                </Grid>
                                <Grid item xs={6}>
                                    <AnyField
                                        name="departmentId"
                                        label="Подразделение"
                                        value={master.departmentId}
                                        onChange={this.handleChange}
                                        error={!master.departmentId}
                                        fullWidth
                                        select
                                    >
                                        <MenuItem value={null}>Не выбрано</MenuItem>
                                        {departments.map(item => <MenuItem key={item.id}
                                                                           value={item.id}>{item.name}</MenuItem>)}
                                    </AnyField>
                                </Grid>
                                {master.employeePost === 'master' && <Grid item xs={6}>
                                    <FloatField
                                        name="mastersMaxWorkHours"
                                        label="Максимальная загрузка в день"
                                        value={master.mastersMaxWorkHours}
                                        onChange={this.handleChange}
                                        error={!master.mastersMaxWorkHours}
                                        fullWidth
                                    />
                                </Grid>}
                                <Grid item xs={6}>
                                    <AnyField
                                        name="inn"
                                        label="ИНН"
                                        value={master.inn}
                                        fullWidth
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <AnyField
                                        name="address"
                                        label="Адрес"
                                        value={master.address}
                                        fullWidth
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <PhoneField
                                        id="phone"
                                        label="Телефон"
                                        value={master.phone}
                                        defaultCountry="ru"
                                        fullWidth
                                        onChange={this.handleChangeDate('phone')}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <DateField
                                        label="День рождения"
                                        value={master.birthDate}
                                        format="DD.MM.YYYY"
                                        onChange={this.handleChangeDate('birthDate')}
                                        disableFuture
                                        openTo={master.birthDate ? 'date' : 'year'}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <AnyField
                                        name="birthPlace"
                                        label="Место рождения"
                                        value={master.birthPlace}
                                        fullWidth
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <AnyField
                                        name="firstName"
                                        label="Имя"
                                        value={master.firstName}
                                        fullWidth
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <AnyField
                                        name="lastName"
                                        label="Фамилия"
                                        value={master.lastName}
                                        fullWidth
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <AnyField
                                        name="thirdName"
                                        label="Отчество"
                                        value={master.thirdName}
                                        fullWidth
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <UploadFilesField
                                        id="photo"
                                        label="Фото (только .jpg)"
                                        value={master.photo}
                                        onChange={this.handleChangeFile('photo')}
                                        accept=".jpg"
                                        image
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <UploadFilesField
                                        id="certificate"
                                        label="Сертификат (только .jpg)"
                                        value={master.certificate}
                                        onChange={this.handleChangeFile('certificate')}
                                        accept=".jpg"
                                        image
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <ColorField
                                        name="lineColor"
                                        label="Цвет колонки"
                                        value={master.lineColor}
                                        onChange={this.handleChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <MultiSelectFromItemsField
                                        name="currenciesInBillReport"
                                        items={currencies}
                                        value={master.currenciesInBillReport}
                                        onChange={this.handleChange}
                                        valueField="id"
                                        textField="text"
                                        label="Только выбранные валюты в отчете"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CheckboxField
                                        name="canHaveMoney"
                                        label="Может иметь деньги"
                                        value={master.canHaveMoney}
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CheckboxField
                                        name="hideInCalendar"
                                        label="Скрыть из графика"
                                        value={master.hideInCalendar}
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CheckboxField
                                        name="showOnSite"
                                        label="Показывать на сайте"
                                        value={master.showOnSite}
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CheckboxField
                                        name="notPerson"
                                        label="Не человек"
                                        value={master.notPerson}
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CheckboxField
                                        name="exportAdminsAndMastersDashboard"
                                        label="Экспорт в дашборд админов и мастеров"
                                        value={master.exportAdminsAndMastersDashboard}
                                        onChange={this.handleChange}
                                        disabled={master.employeePost !== employeePostAdministrator}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AnyField
                                        name="comment"
                                        label="Комментарий"
                                        value={master.comment}
                                        fullWidth
                                        multiline={true}
                                        rows={4}
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <AnyField
                                        name="description"
                                        label="Описание"
                                        value={master.description}
                                        fullWidth
                                        multiline={true}
                                        rows={4}
                                        onChange={this.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectFromItemsField
                                        items={genders}
                                        name="gender"
                                        label="Пол"
                                        value={master.gender}
                                        fullWidth
                                        nullable
                                        nullableText="Не указан"
                                        onChange={this.handleChange}
                                        textField="text"
                                    />
                                </Grid>
                                {gratuityLinkQR &&
                                <Grid item xs={4}>
                                    <p>Ссылка на оплату чаевых через сайт</p>
                                    <img src={gratuityLinkQR} alt="QR" style={{width: 200}}/>
                                </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <AnyField
                                        name="mainBusinessUnitId"
                                        label="Основной бизнес юнит"
                                        value={master.mainBusinessUnitId}
                                        fullWidth
                                        select
                                        onChange={this.handleChange}
                                    >
                                        {businessUnits.filter(bu => master.businessUnitsId.indexOf(bu.id) >= 0).map(bu =>
                                            <MenuItem key={bu.id} value={bu.id}>{bu.name}</MenuItem>
                                        )}
                                    </AnyField>
                                </Grid>
                                <Grid item xs={12}>
                                    <AnyField
                                        id="filterParlour"
                                        label="Работает в бизнес юнитах"
                                        value={filterParlour}
                                        fullWidth
                                        onChange={this.handleFilterParlour}
                                    />
                                    <List className={classes.changeParlour} dense>
                                        {businessUnits.map(value => {
                                            return (!filterParlour || regexFilterParlour.test(value.name)
                                                    ? <ListItem key={value.id} button
                                                                onClick={this.handleChangeParlour(value.id)}>
                                                        <ListItemText primary={value.name}/>
                                                        <ListItemSecondaryAction>
                                                            <Checkbox
                                                                onChange={this.handleChangeParlour(value.id)}
                                                                checked={master.businessUnitsId.indexOf(value.id) !== -1}
                                                            />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                    : null
                                            )
                                        })}
                                    </List>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.container}>

                    </Grid>

                </Content>
            </React.Fragment>
        );
    }
}
